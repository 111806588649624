import useSWR from "swr";
import { arrayURLFetcher } from "lib/legacy";
import { ARBITRUM, AVALANCHE, AVALANCHE_FUJI, FUSE, FUSE_SPARK_NET } from "config/chains";
import { getServerUrl } from "config/backend";
const ACTIVE_CHAIN_IDS = [FUSE];

export default function useFeesSummaryWednesday() {
  const { data: feesSummaryWednesday, error } = useSWR(
    ACTIVE_CHAIN_IDS.map((chainId) => getServerUrl(chainId, "/fees_summary/wed_day")),
    {
      fetcher: arrayURLFetcher,
    }
  );

  const feesSummaryWednesdayByChain = {};
  for (let i = 0; i < ACTIVE_CHAIN_IDS.length; i++) {
    if (feesSummaryWednesday && feesSummaryWednesday.length === ACTIVE_CHAIN_IDS.length) {
      feesSummaryWednesdayByChain[ACTIVE_CHAIN_IDS[i]] = feesSummaryWednesday[i];
    } else {
      feesSummaryWednesdayByChain[ACTIVE_CHAIN_IDS[i]] = {};
    }
  }

  return { data: feesSummaryWednesdayByChain };
}
