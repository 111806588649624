import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, MAINNET, AVALANCHE_FUJI, FUSE, FUSE_SPARK_NET } from "./chains";

export const GMX_STATS_API_URL = "https://api.mirakle.io/api/v1";

const BACKEND_URLS = {
  default: "https://api.mirakle.io/api/v1",
  [MAINNET]: "https://api.mirakle.io/api/v1",
  [ARBITRUM_TESTNET]: "https://api.mirakle.io/api/v1",
  [ARBITRUM]: "https://api.mirakle.io/api/v1",
  [AVALANCHE]: "https://api.mirakle.io/api/v1",
  [AVALANCHE_FUJI]: "https://api.mirakle.io/api/v1",
  [FUSE_SPARK_NET]: "https://api.mirakle.io/api/v1",
  [FUSE]: "https://api.mirakle.io/api/v1",
};

const FAUCET_URL = {
  default: "",
  [AVALANCHE_FUJI]: "",
  [FUSE_SPARK_NET]: "https://faucet.mirakle.io",
};

export const getFaucetUrl = (chainId: number) => {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }
  return FAUCET_URL[chainId] || FAUCET_URL.default;
};

export function getServerBaseUrl(chainId: number) {
  if (!chainId) {
    throw new Error("chainId is not provided");
  }

  if (document.location.hostname.includes("deploy-preview")) {
    const fromLocalStorage = localStorage.getItem("SERVER_BASE_URL");
    if (fromLocalStorage) {
      return fromLocalStorage;
    }
  }

  return BACKEND_URLS[chainId] || BACKEND_URLS.default;
}

export function getServerUrl(chainId: number, path: string) {
  return `${getServerBaseUrl(chainId)}${path}`;
}
