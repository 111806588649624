import { ethers } from "ethers";
import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, AVALANCHE_FUJI, MAINNET, TESTNET, FUSE, FUSE_SPARK_NET } from "./chains";

const { AddressZero } = ethers.constants;

export const XGMT_EXCLUDED_ACCOUNTS = [
  "0x330eef6b9b1ea6edd620c825c9919dc8b611d5d5",
  "0xd9b1c23411adbb984b1c4be515fafc47a12898b2",
  "0xa633158288520807f91ccc98aa58e0ea43acb400",
  "0xffd0a93b4362052a336a7b22494f1b77018dd34b",
];

const CONTRACTS = {
  [MAINNET]: {
    // bsc mainnet
    Treasury: "0xa44E7252a0C137748F523F112644042E5987FfC7",
    BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    GMT: "0x99e92123eB77Bc8f999316f622e5222498438784",
    Vault: "0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B",
    Router: "0xD46B23D042E976F8666F554E928e0Dc7478a8E1f",
    Reader: "0x087A618fD25c92B61254DBe37b09E5E8065FeaE7",
    AmmFactory: "0xBCfCcbde45cE874adCB698cC183deBcF17952812",
    AmmFactoryV2: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73",
    OrderBook: "0x1111111111111111111111111111111111111111",
    OrderBookReader: "0x1111111111111111111111111111111111111111",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDG: "0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7",
    NATIVE_TOKEN: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    XGMT: "0xe304ff0983922787Fd84BC9170CD21bF78B16B10",
    GMT_USDG_PAIR: "0xa41e57459f09a126F358E118b693789d088eA8A0",
    XGMT_USDG_PAIR: "0x0b622208fc0691C2486A3AE6B7C875b4A174b317",
    GMT_USDG_FARM: "0x3E8B08876c791dC880ADC8f965A02e53Bb9C0422",
    XGMT_USDG_FARM: "0x68D7ee2A16AB7c0Ee1D670BECd144166d2Ae0759",
    USDG_YIELD_TRACKER: "0x0EF0Cf825B8e9F89A43FfD392664131cFB4cfA89",
    XGMT_YIELD_TRACKER: "0x82A012A9b3003b18B6bCd6052cbbef7Fa4892e80",
    GMT_USDG_FARM_TRACKER_XGMT: "0x08FAb024BEfcb6068847726b2eccEAd18b6c23Cd",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd8E26637B34B2487Cad1f91808878a391134C5c2",
    XGMT_USDG_FARM_TRACKER_XGMT: "0x026A02F7F26C1AFccb9Cba7C4df3Dc810F4e92e8",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x22458CEbD14a9679b2880147d08CA1ce5aa40E84",
    AUTO: "0xa184088a740c695E156F91f5cC086a06bb78b827",
    AUTO_USDG_PAIR: "0x0523FD5C53ea5419B4DAF656BC1b157dDFE3ce50",
    AUTO_USDG_FARM: "0xE6958298328D02051769282628a3b4178D0F3A47",
    AUTO_USDG_FARM_TRACKER_XGMT: "0x093b8be41c1A30704De84a9521632f9a139c08bd",
    AUTO_USDG_FARM_TRACKER_NATIVE: "0x23ed48E5dce3acC7704d0ce275B7b9a0e346b63A",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  [TESTNET]: {
    // bsc testnet
    Vault: "0x1B183979a5cd95FAF392c8002dbF0D5A1C687D9a",
    Router: "0x10800f683aa564534497a5b67F45bE3556a955AB",
    Reader: "0x98D4742F1B6a821bae672Cd8721283b91996E454",
    AmmFactory: "0x6725f303b657a9451d8ba641348b6761a6cc7a17",
    AmmFactoryV2: "0x1111111111111111111111111111111111111111",
    OrderBook: "0x9afD7B4f0b58d65F6b2978D3581383a06b2ac4e9",
    OrderBookReader: "0x0713562970D1A802Fa3FeB1D15F9809943982Ea9",
    GmxMigrator: "0xDEF2af818514c1Ca1A9bBe2a4D45E28f260063f9",
    USDG: "0x2D549bdBf810523fe9cd660cC35fE05f0FcAa028",
    GMT: "0xedba0360a44f885ed390fad01aa34d00d2532817",
    NATIVE_TOKEN: "0x612777Eea37a44F7a95E3B101C39e1E2695fa6C2",
    XGMT: "0x28cba798eca1a3128ffd1b734afb93870f22e613",
    GMT_USDG_PAIR: "0xe0b0a315746f51932de033ab27223d85114c6b85",
    XGMT_USDG_PAIR: "0x0108de1eea192ce8448080c3d90a1560cf643fa0",
    GMT_USDG_FARM: "0xbe3cB06CE03cA692b77902040479572Ba8D01b0B",
    XGMT_USDG_FARM: "0x138E92195D4B99CE3618092D3F9FA830d9A69B4b",
    USDG_YIELD_TRACKER: "0x62B49Bc3bF252a5DB26D88ccc7E61119e3179B4f",
    XGMT_YIELD_TRACKER: "0x5F235A582e0993eE9466FeEb8F7B4682993a57d0",
    GMT_USDG_FARM_TRACKER_XGMT: "0x4f8EE3aE1152422cbCaFACd4e3041ba2D859913C",
    GMT_USDG_FARM_TRACKER_NATIVE: "0xd691B26E544Fe370f39A776964c991363aF72e56",
    XGMT_USDG_FARM_TRACKER_XGMT: "0xfd5617CFB082Ba9bcD62d654603972AE312bC695",
    XGMT_USDG_FARM_TRACKER_NATIVE: "0x0354387DD85b7D8aaD1611B3D167A384d6AE0c28",
    GMT_GMX_IOU: "0x47052469970C2484729875CC9E2dd2683fcE71fb",
    XGMT_GMX_IOU: "0xeB3733DFe3b68C9d26898De2493A3Bb59FDb4A7B",
    GMT_USDG_GMX_IOU: "0x481312655F81b5e249780A6a49735335BF6Ca7f4",
    XGMT_USDG_GMX_IOU: "0x8095F1A92526C304623483018aA28cC6E62EB1e1",
  },
  [ARBITRUM_TESTNET]: {
    // arbitrum testnet
    Vault: "0xBc9BC47A7aB63db1E0030dC7B60DDcDe29CF4Ffb",
    Router: "0xe0d4662cdfa2d71477A7DF367d5541421FAC2547",
    VaultReader: "0xFc371E380262536c819D12B9569106bf032cC41c",
    Reader: "0x2E093c70E3A7E4919611d2555dFd8D697d2fC0a1",
    GlpManager: "0xD875d99E09118d2Be80579b9d23E83469077b498",
    RewardRouter: "0x0000000000000000000000000000000000000000",
    RewardReader: "0x0000000000000000000000000000000000000000",
    NATIVE_TOKEN: "0xB47e6A5f8b33b3F17603C83a0535A9dcD7E32681",
    GLP: "0xb4f81Fa74e06b5f762A104e47276BA9b2929cb27",
    GMX: "0x0000000000000000000000000000000000000000",
    ES_GMX: "0x0000000000000000000000000000000000000000",
    BN_GMX: "0x0000000000000000000000000000000000000000",
    USDG: "0xBCDCaF67193Bf5C57be08623278fCB69f4cA9e68",
    ES_GMX_IOU: "0x0000000000000000000000000000000000000000",
    StakedGmxTracker: "0x0000000000000000000000000000000000000000",
    BonusGmxTracker: "0x0000000000000000000000000000000000000000",
    FeeGmxTracker: "0x0000000000000000000000000000000000000000",
    StakedGlpTracker: "0x0000000000000000000000000000000000000000",
    FeeGlpTracker: "0x0000000000000000000000000000000000000000",

    StakedGmxDistributor: "0x0000000000000000000000000000000000000000",
    StakedGlpDistributor: "0x0000000000000000000000000000000000000000",

    GmxVester: "0x0000000000000000000000000000000000000000",
    GlpVester: "0x0000000000000000000000000000000000000000",

    OrderBook: "0xebD147E5136879520dDaDf1cA8FBa48050EFf016",
    OrderBookReader: "0xC492c8d82DC576Ad870707bb40EDb63E2026111E",

    PositionRouter: "0xB4bB78cd12B097603e2b55D2556c09C17a5815F8",
    PositionManager: "0x168aDa266c2f10C1F37973B213d6178551030e44",

    // UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0x0000000000000000000000000000000000000000",
    ReferralReader: "0x0000000000000000000000000000000000000000",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
  },
  [ARBITRUM]: {
    // arbitrum mainnet
    Vault: "0x489ee077994B6658eAfA855C308275EAd8097C4A",
    Router: "0xaBBc5F99639c9B6bCb58544ddf04EFA6802F4064",
    VaultReader: "0xfebB9f4CAC4cD523598fE1C5771181440143F24A",
    Reader: "0x2b43c90D1B727cEe1Df34925bcd5Ace52Ec37694",
    GlpManager: "0x3963FfC9dff443c2A94f21b129D429891E32ec18",
    RewardRouter: "0xA906F338CB21815cBc4Bc87ace9e68c87eF8d8F1",
    GlpRewardRouter: "0xB95DB5B167D75e6d04227CfFFA61069348d271F5",
    RewardReader: "0x8BFb8e82Ee4569aee78D03235ff465Bd436D40E0",
    NATIVE_TOKEN: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    GLP: "0x4277f8F2c384827B5273592FF7CeBd9f2C1ac258",
    GMX: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    ES_GMX: "0xf42Ae1D54fd613C9bb14810b0588FaAa09a426cA",
    BN_GMX: "0x35247165119B69A40edD5304969560D0ef486921",
    USDG: "0x45096e7aA921f27590f8F19e457794EB09678141",
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954",
    StakedGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    BonusGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    FeeGmxTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",
    StakedGlpTracker: "0x1aDDD80E6039594eE970E5872D247bf0414C8903",
    FeeGlpTracker: "0x4e971a87900b931fF39d1Aad67697F49835400b6",

    StakedGmxDistributor: "0x23208B91A98c7C1CD9FE63085BFf68311494F193",
    StakedGlpDistributor: "0x60519b48ec4183a61ca2B8e37869E675FD203b34",

    GmxVester: "0x199070DDfd1CFb69173aa2F7e20906F26B363004",
    GlpVester: "0xA75287d2f8b217273E7FCD7E86eF07D33972042E",

    OrderBook: "0x09f77E8A13De9a35a7231028187e9fD5DB8a2ACB",
    OrderExecutor: "0x7257ac5D0a0aaC04AA7bA2AC0A6Eb742E332c3fB",
    OrderBookReader: "0xa27C20A7CF0e1C68C0460706bB674f98F362Bc21",

    PositionRouter: "0xb87a436B93fFE9D75c5cFA7bAcFff96430b09868",
    PositionManager: "0x75E42e6f01baf1D6022bEa862A28774a9f8a4A0C",

    UniswapGmxEthPool: "0x80A9ae39310abf666A87C743d6ebBD0E8C42158E",
    ReferralStorage: "0xe6fab3f0c7199b0d34d7fbe83394fc0e0d06e99d",
    ReferralReader: "0x8Aa382760BCdCe8644C33e6C2D52f6304A76F5c8",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
  },
  [AVALANCHE]: {
    // avalanche
    Vault: "0x9ab2De34A33fB459b538c43f251eB825645e8595",
    Router: "0x5F719c2F1095F7B9fc68a68e35B51194f4b6abe8",
    VaultReader: "0x66eC8fc33A26feAEAe156afA3Cb46923651F6f0D",
    Reader: "0x2eFEE1950ededC65De687b40Fd30a7B5f4544aBd",
    GlpManager: "0xD152c7F25db7F4B95b7658323c5F33d176818EE4",
    RewardRouter: "0x82147C5A7E850eA4E28155DF107F2590fD4ba327",
    GlpRewardRouter: "0xB70B91CE0771d3f4c81D87660f71Da31d48eB3B3",
    RewardReader: "0x04Fc11Bd28763872d143637a7c768bD96E44c1b6",
    NATIVE_TOKEN: "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    GLP: "0x01234181085565ed162a948b6a5e88758CD7c7b8",
    GMX: "0x62edc0692BD897D2295872a9FFCac5425011c661",
    ES_GMX: "0xFf1489227BbAAC61a9209A08929E4c2a526DdD17",
    BN_GMX: "0x8087a341D32D445d9aC8aCc9c14F5781E04A26d2",
    USDG: "0xc0253c3cC6aa5Ab407b5795a04c28fB063273894",
    ES_GMX_IOU: "0x6260101218eC4cCfFF1b778936C6f2400f95A954", // placeholder address

    StakedGmxTracker: "0x2bD10f8E93B3669b6d42E74eEedC65dd1B0a1342",
    BonusGmxTracker: "0x908C4D94D34924765f1eDc22A1DD098397c59dD4",
    FeeGmxTracker: "0x4d268a7d4C16ceB5a606c173Bd974984343fea13",
    StakedGlpTracker: "0x9e295B5B976a184B14aD8cd72413aD846C299660",
    FeeGlpTracker: "0xd2D1162512F927a7e282Ef43a362659E4F2a728F",

    StakedGmxDistributor: "0xfc5A1A6EB076a2C7aD06eD22C90d7E710E35ad0a",
    StakedGlpDistributor: "0xDd593Cf40734199afc9207eBe9ffF23dA4Bf7720",

    GmxVester: "0x472361d3cA5F49c8E633FB50385BfaD1e018b445",
    GlpVester: "0x62331A7Bd1dfB3A7642B7db50B5509E57CA3154A",

    OrderBook: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderExecutor: "0x4296e307f108B2f583FF2F7B7270ee7831574Ae5",
    OrderBookReader: "0xccFE3E576f8145403d3ce8f3c2f6519Dae40683B",

    PositionRouter: "0xffF6D276Bc37c61A23f06410Dce4A400f66420f8",
    PositionManager: "0xA21B83E579f4315951bA658654c371520BDcB866",

    TraderJoeGmxAvaxPool: "0x0c91a070f862666bbcce281346be45766d874d98",
    ReferralStorage: "0x827ed045002ecdabeb6e2b0d1604cf5fc3d322f8",
    ReferralReader: "0x505Ce16D3017be7D76a7C2631C0590E71A975083",

    Multicall: "0xcA11bde05977b3631167028862bE2a173976CA11",
  },
  [AVALANCHE_FUJI]: {
    Vault: "0xF99deD9D902d146624B740F400252013Ba831B8E",
    Router: "0xDeA1Cb0B130ac5cDcC7c85b99c87B39CDA71d3f4",
    VaultReader: "0x0DA384707FFae7B751C22D53d05Ea34318adC853",
    Reader: "0x40705Df7565c83ed67f18F38A1Eeec8255F0FC29",
    GlpManager: "0x3980dEe56dd30A0B513DBE24aD440774359662bf",
    RewardRouter: "0xE7B1d3c928169792c5f23dAda05ED273825bCc22",
    GlpRewardRouter: "0x46c9736D55545EB25230977b4F602CD6c5bB986c",
    RewardReader: "0x17DAE7C87Af2fEbBa911aF6156BCfb01cd7c30Eb",
    NATIVE_TOKEN: "0x378f663Bb1A9A59a2738Cb09D4aDFEE2274600cB",
    GLP: "0x2241e5de846755F44634603159738d0ACB9596A7",
    GMX: "0xb9f1a8Cb43dec955e66d99420a83A3c27F6E6052",
    ES_GMX: "0x6Dd30956EEE9c47bE0195E847cE3cDe0B578ba34",//EsQiji
    BN_GMX: "0xc8Ac05A88FAf1763F8cAbe69a0Da970463890C87",//bnQiji
    USDG: "0x0F368DA54026c97179Daa70Ce65368a2e85b241D",
    ES_GMX_IOU: "0x2CE70FAD8946C90F051a6e45D2a593fD7b740C4a", // placeholder address

    StakedGmxTracker: "0x9F0C06cc6532FAF38562C321B85D79E5542b9819",//sQiji
    BonusGmxTracker: "0x3d1aA4D92E50eA33b3831c2db1fF9aE9F997Adff",//sbQiji
    FeeGmxTracker: "0x7C98f98B2531f7Ad7D6781e8844121DCfEfb88E5", //sbfQiji StakedGmx
    StakedGlpTracker: "0x38028C9aCce8cE7Dcb8dCbf9fCA8F67937407d7C",//fsQLP StakedGlpTracker
    FeeGlpTracker: "0xcCE0d0f35f84d33b7D664ec92C8619e7C73B4dB1", //fQLP FeeGlpTracker

    StakedGmxDistributor: "0xf86b4c8536e5dFB3b86b7834a1D1199545Ad93Fe", // sQijiRewardDistributor
    StakedGlpDistributor: "0x4fD53eF2fb1Bc668654762dE49e47dbD9Dc3bC38",// TODO: stakedGlpDistributor

    GmxVester: "0x19ffeF225B02cb635AeB3Ea67561De0ad8989d18", //vQiji
    GlpVester: "0x579f2E1273CFC7d6CbFcFa3832D0689b833c7F65",  //vQLP

    OrderBook: "0x1d407c4c1aE79cdF65904D536A6C3BF42bAC0869",
    OrderExecutor: "0x1d407c4c1aE79cdF65904D536A6C3BF42bAC0869",
    OrderBookReader: "0x6701E0B21094C6dB0B2994CD52E532079F76bAC1",

    PositionRouter: "0x61C2b44305C3DaDD59d5cA69De7294e097bD61d3",
    PositionManager: "0x930A43A80851374FDAA724e02361C370300866F4",

    TraderJoeGmxAvaxPool: "0x8afe78327039a577cc2a4c118e0fb74f1026707d",
    ReferralStorage: "0x069ad21C365539A4eC695C2bf4b24d3d103525d9",
    ReferralReader: '0x0f8B7dCA33D0cd2396B9E1A49AA06e9173012a83',

    Multicall: "0x7A0FF38ACe707ed289530f271C4dc34cFAcFDE13",
  },
  [FUSE_SPARK_NET]: {
    Vault: "0xF5E6eAe4DB90cb67B06CDc117F28CDa925Af40D0",
    Router: "0x4461B38C03A0720C849B6fB50D9f2605C384539C",
    VaultReader: "0x219662701371823d25cDA440057F124E8c58039C",
    Reader: "0x35495C9c6823125FE2b40061e641B04060e5B8a2",
    GlpManager: "0x7F575C0EBe65BC751005eF675BAad78032bC6E09",
    RewardRouter: "0x8ff5Fa412a1e62ddD848896b282101F688418ad6",
    GlpRewardRouter: "0xE882fEe535C8AB0420549365828B7636c77Cf4C1",
    RewardReader: "0xc89A11bd6Ac9B2dE19D8411eE3bbc0281389d00E",
    NATIVE_TOKEN: "0x59a2D0b1d202723262b7C27E6c2251F41c7f1C5b",
    GLP: "0x52a4123D89bDAc46cbB4613225AEb0EA4A96a20D",
    GMX: "0xeA8a46E3ddf2fFc7a18EDa4f7aE3A35aC7aD6541",
    ES_GMX: "0x935cd7F1E082E0E6431a99325f2fecE5ea7C4b71",//EsQiji
    BN_GMX: "0xc8106f3c1D9D92374c3f59d3192A40ea6b189Da8",//bnQiji
    USDG: "0x04857a6AE13b4EA444f8907b59C9d61e9556a776",
    ES_GMX_IOU: "0x2CE70FAD8946C90F051a6e45D2a593fD7b740C4a", // placeholder address

    StakedGmxTracker: "0xf1690f87dBe69c87BB2E17D424F5459cD0DEe9b6",//sQiji
    BonusGmxTracker: "0xbB5Ad0206763e79275729333C4eB1fCB1e5D6900",//sbQiji
    FeeGmxTracker: "0xD6F3f011CB4611cf49EAE521710D8033780A8135", //sbfQiji StakedGmx
    StakedGlpTracker: "0x20881672A8b6cF6DA03FA4914362bAc37Df193d4",//fsQLP StakedGlpTracker
    FeeGlpTracker: "0x100F7a1e4BC08aC9342c14C986E49A59d7Cd0852", //fQLP FeeGlpTracker

    StakedGmxDistributor: "0x7c585D733186B28029F7bDA3C589e26432fDa637", // sQijiRewardDistributor
    StakedGlpDistributor: "0xd0CD05e77e38566142F2705F64763bbe05948b2F",// TODO: stakedGlpDistributor

    GmxVester: "0x030BccEC58141652AE254321D9E1B814edF7d553", //vQiji
    GlpVester: "0x2e32ee2a35D3512A0C4735DDAF68965963819F26",  //vQLP

    OrderBook: "0x364AAC71E778De7b6bcfaEfc5c5f6898bDF16959",
    OrderExecutor: "0x364AAC71E778De7b6bcfaEfc5c5f6898bDF16959",
    OrderBookReader: "0xB9b9DcAe88277b866c1Da3a791Dcd2Bac546ABEd",

    PositionRouter: "0xd7091CAD4E0489C1Bd964fE3cc2D06d28644b408",
    PositionManager: "0xaC0A6FBe90C2bcDeB3508d9b1a9F518d6740F3c5",

    TraderJoeGmxAvaxPool: "",
    ReferralStorage: "0x7d9A3dF3e5d970F8F518E946e718ca89f5FD6A58",
    ReferralReader: '0x36d5a67c55c4597d26Fbd200b3cBcC665C5807DB',

    Multicall: "0x883Fb3a820cCeB48A83B9628f33CA1aeeB50cBF7",
  },
  [FUSE]: {
    Vault: "0x2EB12e4B1057ef2d0C300C41493B599B028dB00f",
    Router: "0x16df389493C94A1A4631D53FE84c5cad533BE386",
    VaultReader: "0x9E0367846A0907FAfE7a94f1A944Dc7662660e40",
    Reader: "0xd1600e998b2a42c0777620AD9B23d44FFe35eEa0",
    GlpManager: "0x4bdbaBBD126DceAC8720f71f90efb1EB7dFd5093",
    RewardRouter: "0xEBF6167976899AB2995EAd3a6046FE3d186feD5d",
    GlpRewardRouter: "0x8fA616587c3897b6263a29A9eC704408BB9bff47",
    RewardReader: "0x8266e5a872065d1DE321cFAA4846165c20C97bcB",
    NATIVE_TOKEN: "0x0BE9e53fd7EDaC9F859882AfdDa116645287C629",
    GLP: "0xCcEc1EedD023800dB3019c7891e19ec01c720bF3",
    GMX: "0x4b9aE621E54BF1ecFe39366BCA0018d97A2D510b",
    ES_GMX: "0x5D61190DfD30D2Bd22f1903aad6C4D077F1A1d76",//EsQiji
    BN_GMX: "0x55545F45cf19Be5a6aDD66348Cc9271bC7220b21",//bnQiji
    USDG: "0x48c50232Df57fEAf4a82fBE5E350467A4b7B5D5e",
    ES_GMX_IOU: "0xAFD5703E85cE30cDfca5bDf635c70639E3d7b1F3", // placeholder address

    StakedGmxTracker: "0x8F7bCecC354037Bcf63DB11A336dA5d49b1316d8",//sQiji
    BonusGmxTracker: "0xD0fB89089ae4505599017e46DBd2B45aaEA8f2fA",//sbQiji
    FeeGmxTracker: "0xdD4F8354748c543dBE9829B89aC63a5474a71674", //sbfQiji StakedGmx
    StakedGlpTracker: "0xfBC3A64E42E0b067D8eA0CC422dDcDe77dD272f5",//fsQLP StakedGlpTracker
    FeeGlpTracker: "0x2b652542117DC7fe256bA8bBA7A03e22797FED99", //fQLP FeeGlpTracker

    StakedGmxDistributor: "0x323480baF4b3347A4166402E0bf8821556526CCC", // sQijiRewardDistributor
    StakedGlpDistributor: "0x0DED0a535D21F0bcFaEc8E42E367a4411FA9d696",// TODO: stakedGlpDistributor

    GmxVester: "0xCBBCf1db36FBB2C787FE021801356Bb09827794e", //vQiji
    GlpVester: "0x2CDe75f21640ED5Bb145E88b486706F3a4a0c063",  //vQLP

    OrderBook: "0x4559172F5Bba07eE558Ff59457dbF9d2aE945e9A",
    OrderExecutor: "0x4559172F5Bba07eE558Ff59457dbF9d2aE945e9A",
    OrderBookReader: "0x31C1a741Cbe3EbFBbbbA95E76A2838813493b4Fb",

    PositionRouter: "0x9cA7368863137C755Ac4B51EF9a07Cebe5E162B7",
    PositionManager: "0x3a6d6Ed06d41cAEB0e1366CA8b7328b5DA1eB9ca",

    TraderJoeGmxAvaxPool: "0x1b2d1e4d84e594330f61a5940d42c300783501fb",
    ReferralStorage: "0xAB802E4420B817Db7904e1C25af65C28a71e5aF6",
    ReferralReader: "0x8266e5a872065d1DE321cFAA4846165c20C97bcB",

    Multicall: "0x0D5aB38e65FbB679d3739d20803Ef09B2ddDdA02",
  },
};

export function getContract(chainId: number, name: string): string {
  if (!CONTRACTS[chainId]) {
    throw new Error(`Unknown chainId ${chainId}`);
  }

  if (!CONTRACTS[chainId][name]) {
    throw new Error(`Unknown contract "${name}" for chainId ${chainId}`);
  }

  return CONTRACTS[chainId][name];
}
