import { createClient } from "./utils";
import { SUBGRAPH_URLS } from "config/subgraph";
import { ARBITRUM, ARBITRUM_TESTNET, AVALANCHE, AVALANCHE_FUJI, FUSE, FUSE_SPARK_NET } from "config/chains";

export const chainlinkClient = createClient(SUBGRAPH_URLS.common.chainLink);

export const arbitrumGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].stats);
export const arbitrumReferralsGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].referrals);
export const nissohGraphClient = createClient(SUBGRAPH_URLS[ARBITRUM].nissohVault);

export const avalancheGraphClient = createClient(SUBGRAPH_URLS[AVALANCHE].stats);
export const avalancheReferralsGraphClient = createClient(SUBGRAPH_URLS[AVALANCHE].referrals);
export const avalancheFujiGraphClient = createClient(SUBGRAPH_URLS[AVALANCHE_FUJI].stats);
export const avalancheFujiReferralsGraphClient = createClient(SUBGRAPH_URLS[AVALANCHE_FUJI].referrals);

export const fusesparkGraphClient = createClient(SUBGRAPH_URLS[FUSE_SPARK_NET].stats);
export const fusesparkReferralsGraphClient = createClient(SUBGRAPH_URLS[FUSE_SPARK_NET].referrals);

export const fuseGraphClient = createClient(SUBGRAPH_URLS[FUSE].stats);
export const fuseReferralsGraphClient = createClient(SUBGRAPH_URLS[FUSE].referrals);

export function getGmxGraphClient(chainId: number) {
  if (chainId === ARBITRUM) {
    return arbitrumGraphClient;
  } else if (chainId === AVALANCHE) {
    return avalancheGraphClient;
  } else if (chainId === ARBITRUM_TESTNET) {
    return null;
  }
  else if (chainId === AVALANCHE_FUJI) {
    return avalancheFujiGraphClient;
  }
  else if (chainId === FUSE_SPARK_NET) {
    return fusesparkGraphClient;
  }
  else if (chainId === FUSE) {
    return fuseGraphClient;
  }

  throw new Error(`Unsupported chain ${chainId}`);
}
