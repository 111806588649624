import { ARBITRUM, AVALANCHE, AVALANCHE_FUJI, FUSE, FUSE_SPARK_NET } from "./chains";

export const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    stats: "https://api.thegraph.com/subgraphs/name/gmx-io/gmx-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/gmx-io/gmx-arbitrum-referrals",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault",
  },

  [AVALANCHE]: {
    stats: "https://api.thegraph.com/subgraphs/name/gmx-io/gmx-avalanche-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/gmx-io/gmx-avalanche-referrals",
  },
  [AVALANCHE_FUJI]: {
    stats: "https://api.thegraph.com/subgraphs/name/hoangthaihuy/vlx-avalanche-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/hoangthaihuy/vlx-avalanche-referrals",
  },
  [FUSE_SPARK_NET]: {
    stats: "https://fusespark-subgraphs.fiberbox.net/subgraphs/name/fiberblock/fuse-stats",
    referrals: "https://fusespark-subgraphs.fiberbox.net/subgraphs/name/fiberblock/fuse-referrals",
  },
  [FUSE]: {
    stats: "https://api.thegraph.com/subgraphs/name/miraklegraph/mirakle-fuse-stats",
    referrals: "https://api.thegraph.com/subgraphs/name/miraklegraph/mirakle-fuse-referrals",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
